import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "./Order.module.css";
import styles from "../productDetail/ProductDetail.module.css";
import NoProducts from "../../Assessts/Images/NoProducs.png";
import Header from "../../reusableComponents/Header";
import Loader from "../../reusableComponents/Loader/Loader";
// import CustomDropdown from "../../reusableComponents/CustomDropdown/CustomDropdown";
import { path } from "../../Router/Routes.js";
import OrderList from "./OrderList";
import {
  getProductByStoreHandler,
  getStockDetails,
} from "../../Store/Product/ProductActions";
import {
  AddOrderListHandler,
  EmptyOrderListHandler,
  OrderListHandler,
  createOrderHandler,
} from "../../Store/OrderList/OrderListActions";
import { getProfileHandler } from "../../Store/Auth/AuthActions";
import {
  getSeriesInfoLocationwise,
  getStoreLocationHanlder,
} from "../../Store/StoreDetails/StoreActions";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import { orderListActions } from "../../Store/OrderList/OrderListSlice";
import { productActions } from "../../Store/Product/ProductSlice";

const CreateOrder = () => {
  let { id, locationid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let statusFilter = "all";
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orderListVisible, setOrderListVisible] = useState(true);
  const [ordersummaryVisible, setOrdersummaryVisible] = useState(false);
  const [summaryValue, setSummaryValue] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [remark, setRemark] = useState("");
  const [orderRemark, setOrderRemark] = useState("");
  const [biilingRate, setBillingRate] = useState(null);

  const [loading, setLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderlist, setOrderlist] = useState([]);
  const profileData = useSelector((state) => state.orderList.profileData);
  const allStockList = useSelector((state) => state.products.allStockList);

  const [products, setProducts] = useState([]);
  const quantityInputRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const [orderType, setOrderType] = useState("P"); // Default to "P"

  useEffect(() => {
    if (selectedLocation?.b2bDispatchOption) {
      const dispatchOption = selectedLocation.b2bDispatchOption;
      if (dispatchOption === "Delivery" || dispatchOption === "Both") {
        setOrderType("D");
      } else if (dispatchOption === "Pickup") {
        setOrderType("P");
      }
    }
  }, [selectedLocation]);

  const handleOrderTypeChange = (e) => {
    setOrderType(e.target.value);
  };

  useEffect(() => {
    if (!B2BauthToken) {
      toast.error("Please login first!");
      navigate(path.login);
    }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));

    if (locationid) {
      dispatch(getSeriesInfoLocationwise(id, locationid)).then((result) => {
        if (result.status === "success") {
          setBillingRate(result.data.BillingRate);
        }
      });
    }

    dispatch(getStoreLocationHanlder(id)).then((result) => {
      if (result.status === "success") {
        // setLocation(result.data);
        const defaultLocation = result.data.find(
          (loc) => loc.LocationId.toString() === locationid
        );
        setSelectedLocation(defaultLocation);
      }
      // else if (result.status === "error") {
      //   toast.error("Something is Wrong, Please try again later.", {
      //     autoClose: false,
      //   });
      // }
    });

    dispatch(getStockDetails(id, locationid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, locationid, B2BauthToken, navigate]);

  useEffect(() => {
    if (B2BauthToken) {
      setOrderLoading(true);
      dispatch(OrderListHandler(B2BauthToken, id, locationid)).then(
        (result) => {
          if (result.status === "success") {
            setOrderlist(result.data.lineItems);
            setOrderLoading(false);
          } else {
            setOrderLoading(false);
          }
        }
      );
    }
  }, [dispatch, B2BauthToken, locationid, id]);

  const handleSearch = (e) => {
    setProductLoading(true);
    setSearchQuery(e.target.value);

    const params = {
      ClientRegID: id,
      LocationID: locationid,
      page: "",
      limit: "",
      active: true,
      search: e.target.value,
      MfgGroupName: "",
    };
    dispatch(getProductByStoreHandler(params)).then((result) => {
      if (result.status === "success") {
        setProductLoading(false);
        setProducts(result.data);
        dispatch(productActions.setProductListHandler(result.data));
      } else {
        setProductLoading(false);
      }
    });
    setOrderListVisible(e.target.value === "");
  };

  const handleRowClick = (product) => {
    setSelectedProduct(product);
    setQuantity(1);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSearchQuery("");
    setSelectedProduct(null);
    setQuantity(1);
    setRemark("");
    setOrderListVisible(true);
  };

  const handleQuantityChange = (e) => {
    setQuantity(Number(e.target.value));
  };
  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const filteredProducts = products.filter((product) => {
    const searchFilter = product?.ERPProductName?.toLowerCase().startsWith(
      searchQuery.toLowerCase()
    );

    const statusFilterMatch =
      statusFilter === "all"
        ? true
        : product?.active === (statusFilter === "Active");
    return searchFilter && statusFilterMatch;
  });

  const calculateTotalAmount = (orderlist) => {
    let totalAmount = 0;
    if (orderlist && orderlist.length > 0) {
      orderlist.forEach((product) => {
        totalAmount += product.BillingRate * product.ProdutQty;
      });
    }
    return totalAmount;
  };

  const renderOrderList = () => (
    <>
      {orderLoading ? (
        <div className={`${classes.ProductEmptyPage}`}>
          <Loader />
        </div>
      ) : (
        <OrderList
          orderlist={orderlist}
          setOrderlist={setOrderlist}
          id={id}
          locationid={locationid}
        />
      )}
    </>
  );

  const handleOrderSummary = (orderlist) => {
    if (orderlist.length > 0) {
      setOrdersummaryVisible(true);
      setSummaryValue(orderlist);
    } else {
      toast.error("Please add product first to order");
    }
  };

  const closeSummaryModal = () => {
    setOrdersummaryVisible(false);
    setSummaryValue(null);
  };

  const addToOrderList = (product) => {
    if (B2BauthToken) {
      const newProduct = {
        LocationId: locationid,
        ProductId: product._id,
        ProductPKID: product.PKID,
        ProductPrice: product.SaleRate,
        ProdutQty: quantity,
        Ptr: product.Ptr,
        Remarks: remark,
      };
      const orderdetail = {
        CustomerId: id,
        StoreId: id,
        lineItems: [newProduct],
      };

      dispatch(AddOrderListHandler(B2BauthToken, orderdetail)).then(
        (result) => {
          if (result.status === "success") {
            dispatch(OrderListHandler(B2BauthToken, id, locationid)).then(
              (result) => {
                if (result.status === "success") {
                  calculateTotalAmount(result.data.lineItems);
                  setSearchQuery("");
                  setOrderlist(result.data.lineItems);
                }
              }
            );
          }
          // else if (result.status === "error") {
          //   toast.error("Something is Wrong, Please try again later.", {
          //     autoClose: false,
          //   });
          // }
        }
      );
    } else {
      alert("Please login first!");
    }
  };

  const handleAddToOrderList = (e) => {
    e.preventDefault();
    addToOrderList(selectedProduct);
    handleCloseModal();
    setSearchQuery("");
    setOrderListVisible(true);
  };

  const orderHandle = (e) => {
    if (orderlist.length > 0) {
      if (selectedLocation != null) {
        setLoading(true);

        e.preventDefault();

        const data = {
          CustomerID: profileData?._id,
          appKeyToken: B2BauthToken,
          OrderDispatchType: orderType,
          CustomerDetail: [
            {
              StoreId: id,
              LocationId: selectedLocation?.LocationId,
              ERPLocationName: selectedLocation?.LocationName,
              PaymentDetails: {
                COD: {
                  cash: false,
                },
                CREDIT_CARD: {
                  CreditCard: true,
                  CreditCardAmt: 150,
                  CreditCardDate: "2023-10-27T11:02:03.464Z",
                  CreditCardNo: "5248515",
                  CreditCardType: 0,
                  CreditDate: "2023-10-29",
                },
                paymentMethod: "CREDIT_CARD",
              },

              couponApplied: "Coupon123",
              payment_date: new Date(),
              payment_status: "Paid",
              transactiondate: new Date(),
              ShippingAddress: {
                Street1: profileData?.AddressLine1,
                Street2: "",
                City: profileData?.city,
                State: profileData?.State,
                Country: "India",
                Pincode: profileData?.Pincode,
                Mobile: profileData?.ContactNumber,
              },
              Party: profileData?.CompanyName,
              BillingAddress: {
                Street1: profileData?.AddressLine1,
                Street2: "",
                City: profileData?.city,
                State: profileData?.State,
                Country: "India",
                Pincode: profileData?.Pincode,
                Mobile: profileData?.ContactNumber,
              },
              total_discount: 0.0,
              total_amount: calculateTotalAmount(orderlist),
              total_shipping: 0.0,
              total_shipping_discount: 0.0,
              OrderFulfillment: {
                OrderType: "Delivery",
                ExpectedDeliveryTime: "2023-11-02T14:00:00Z",
                CurrentProcessingStatus: "Pending",
                SuccessDetails: {
                  DeliveryTo: profileData?.CompanyName,
                  PickedUpBy: "Delivery Service",
                  TimeDate: "2023-11-02T13:30:00Z",
                },
                tracking_link: "https://example.com/tracking",
              },
              needStatus: "Inprogress",
              created_datetime: new Date(),
              PKID: profileData?.customerId,
              DraftMode: 0,
              Remarks: orderRemark,
              ReceivedAmt: calculateTotalAmount(orderlist),
              FKPartyID: profileData?.customerId,
              FKSeriesID: selectedLocation?.b2bPKOrderSeriesId,
              AutoAddCustomer: 0,
              ApplyPromotion: false,
              ProdDetail: orderlist.map((item, index) => ({
                Product_Id: item.ProductId,
                Product_name: item.ProductName,
                ProductStore_Id: id,
                SrNo: index + 1,
                FKProdID: item.ProductPKID,
                Qty: item.ProdutQty || 1,
                Remarks: item.Remark,
                Rate: item.BillingRate,
              })),
            },
          ],
        };

        dispatch(createOrderHandler(data)).then((result) => {
          if (result.status === "success") {
            const details = {
              StoreId: id,
            };

            setLoading(false);
            dispatch(EmptyOrderListHandler(B2BauthToken, details));
            navigate(
              path.orderSucess
                .replace(":id", id)
                .replace(":locationid", locationid)
            );
          } else {
            toast.error("Order creation failed. Please try again.");
            setLoading(false);
          }
        });
      } else {
        e.preventDefault();
        setLoading(false);
        toast.error("Please select a location to order");
      }
    } else {
      e.preventDefault();

      handleCloseModal();
      setLoading(false);
      toast.error("Please add product to order");
    }
  };

  const getStockForProduct = (productPKID) => {
    const stockArray = allStockList.filter(
      (stock) => stock.product_PKID === productPKID.toString()
    );
    if (stockArray.length > 0) {
      return stockArray[0]?.totalStock;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (B2BauthToken) {
      dispatch(getProfileHandler(B2BauthToken, id)).then((result) => {
        if (result.status === "success") {
          if (result.data.registered === true) {
            dispatch(orderListActions.setProfileData(result.data.data[0]));
          }
        }
      });
    }
  }, [B2BauthToken, dispatch, id]);

  useEffect(() => {
    if (showModal && quantityInputRef.current) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [showModal]);

  return (
    <>
      <Header
        pagename={"Create Order"}
        Amount={calculateTotalAmount(orderlist)}
        BtnName={"Place Order"}
        onClick={() => handleOrderSummary(orderlist)}
      />
      <div className={`${styles.ProductDetailWrapper}`}>
        <div className={`${styles.ProductDetailSearch}`}>
          <div className={`${styles.SearchProduct}`}>
            <input
              type="search"
              className={`${styles.ProductSearchBar} form-control`}
              aria-label="Search"
              placeholder="Search Product"
              onChange={handleSearch}
              value={searchQuery}
            />
            <span className={`${styles.SearchIcon}`}>
              <i className="bi bi-search"></i>
            </span>
          </div>
        </div>

        {orderListVisible ? (
          renderOrderList()
        ) : (
          <>
            {productLoading ? (
              <div className={`${classes.ProductEmptyPage}`}>
                <Loader />
              </div>
            ) : filteredProducts.length ? (
              <div
                className={`${styles.ProductDetailTable} ${classes.ProductDetailTable}`}
              >
                <table className={`${styles.TableComponent}`}>
                  <thead className={`${styles.TableComponentHeader}`}>
                    <tr>
                      <th className={`${styles.TableName}`} scope="col">
                        PRODUCT NAME
                      </th>
                      <th className={`${styles.TableName}`} scope="col">
                        BRAND
                      </th>
                      <th className={`${styles.TableName}`} scope="col">
                        CATEGORY
                      </th>
                      <th className={`${styles.TableNum}`} scope="col">
                        STATUS
                      </th>
                      <th
                        className={`${classes.TableNum} text-center`}
                        scope="col"
                      >
                        STOCK
                      </th>
                      <th
                        className={`${styles.TableName} text-end`}
                        scope="col"
                      >
                        RATE
                      </th>
                      <th className={`${styles.TableNum} text-end`} scope="col">
                        MRP
                      </th>

                      <th className={`${styles.TableNum}`} scope="col">
                        SCHEME
                      </th>
                      <th className={`${styles.TableName}`} scope="col">
                        MANUFACTURER
                      </th>

                      <th className={`${styles.TableNum}`} scope="col">
                        STRENGTH
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProducts.map((product, index) => (
                      <tr key={index} onClick={() => handleRowClick(product)}>
                        <td className={`${styles.TableName}`}>
                          {product.ERPProductName}
                        </td>
                        <td className={`${styles.TableName}`}>
                          {product.ERPBrand}
                        </td>
                        <td className={`${styles.TableName}`}>
                          {product.ERPCategory}
                        </td>
                        <td className={`${styles.TableNum}`}>
                          <p
                            className={`${classes.status} ${
                              product.active
                                ? `${classes.DeliveredOrder}`
                                : `${classes.CancelledOrder}`
                            }`}
                          >
                            {product.active ? "Active" : "Inactive"}
                          </p>
                        </td>
                        <td className={`${classes.TableNum} text-center`}>
                          {getStockForProduct(product.PKID).toFixed(2)}
                        </td>
                        <td className={`${styles.TableName} text-end`}>
                          {biilingRate === "M"
                            ? product?.MRP?.toFixed(2)
                            : biilingRate === "S"
                            ? product?.SaleRate?.toFixed(2)
                            : biilingRate === "T"
                            ? product?.TradeRate?.toFixed(2)
                            : biilingRate === "D"
                            ? product?.DistributionRate?.toFixed(2)
                            : biilingRate === "P"
                            ? product?.PurchaseRate?.toFixed(2)
                            : biilingRate === "C"
                            ? product?.CostRate?.toFixed(2)
                            : "Rate not available"}
                        </td>
                        <td className={`${styles.TableNum} text-end`}>
                          {product?.MRP?.toFixed(2)}
                        </td>

                        <td className={`${styles.TableNum}`}>{}</td>
                        <td className={`${styles.TableName}`}>
                          {product.MfgGroupName}
                        </td>

                        <td className={`${styles.TableNum}`}>
                          {product.ERPStrength}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={`${styles.NoImageContainer}`}>
                <img src={NoProducts} alt="no-products-found" />
              </div>
            )}
          </>
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className="p-2">
          <Modal.Title className={classes.modalTitle}>Create Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && (
            <form>
              <div className="mb-3">
                <h5 className={classes.productName}>
                  {selectedProduct.ERPProductName}
                </h5>
              </div>
              <div className={`${classes.QtyFieldBox} mb-3`}>
                <label className="form-label">Select Quantity</label>
                <div className={`${classes.QtyField}`}>
                  <input
                    ref={quantityInputRef}
                    type="text"
                    placeholder="Enter Qty..."
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                  {/* <select className="form-select">
                    <option value="1">Piece</option>
                    <option value="2">Pieces</option>
                    <option value="1">Pack</option>
                    <option value="2">Packs</option>
                  </select> */}
                </div>
              </div>

              <div className={classes.orderAmountList}>
                <div className="mb-2 d-flex justify-content-between">
                  <h6>Rate</h6>
                  <h6>
                    <b>
                      {biilingRate === "M"
                        ? selectedProduct?.MRP?.toFixed(2)
                        : biilingRate === "S"
                        ? selectedProduct?.SaleRate?.toFixed(2)
                        : biilingRate === "T"
                        ? selectedProduct?.TradeRate?.toFixed(2)
                        : biilingRate === "D"
                        ? selectedProduct?.DistributionRate?.toFixed(2)
                        : biilingRate === "P"
                        ? selectedProduct?.PurchaseRate?.toFixed(2)
                        : biilingRate === "C"
                        ? selectedProduct?.CostRate?.toFixed(2)
                        : "Rate not available"}
                    </b>
                  </h6>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <h6>MRP</h6>
                  <h6>
                    <b>{selectedProduct?.MRP?.toFixed(2)}</b>
                  </h6>
                </div>

                <div className="d-flex flex-column mt-1">
                  <h6>Remark:</h6>
                  <textarea
                    className="p-2"
                    value={remark}
                    onChange={handleRemarkChange}
                  ></textarea>
                </div>
              </div>
              <div
                className={`${classes.TotalAmount} mb-3 d-flex justify-content-between`}
              >
                <h6>Total Amount</h6>
                <h6>
                  <b>
                    {(
                      quantity *
                      (biilingRate === "M"
                        ? selectedProduct?.MRP
                        : biilingRate === "S"
                        ? selectedProduct?.SaleRate
                        : biilingRate === "T"
                        ? selectedProduct?.TradeRate
                        : biilingRate === "D"
                        ? selectedProduct?.DistributionRate
                        : biilingRate === "P"
                        ? selectedProduct?.PurchaseRate
                        : biilingRate === "C"
                        ? selectedProduct?.CostRate
                        : 0)
                    ).toFixed(2)}
                  </b>
                </h6>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className={classes.Btn}
                  onClick={(e) => handleAddToOrderList(e)}
                >
                  Add
                </button>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        className={`${classes.orderSummaryModal} order-summary-box`}
        show={ordersummaryVisible}
        onHide={closeSummaryModal}
        centered
      >
        <Modal.Header closeButton className="p-2">
          <Modal.Title className={classes.modalTitle}>
            Order Summary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.ordersummaryMain}>
            <div className={classes.ordersummaryLeft}>
              <div className={`${classes.selectedPlace} mb-2 d-flex`}>
                <h4>{selectedLocation?.LocationName}</h4>
              </div>
              <div className={`${classes.selectedLocation} mt-3 mb-2 d-flex`}>
                <h6>Mobile:</h6>
                <p>{selectedLocation ? selectedLocation.phone : ""}</p>
              </div>

              <div className={`${classes.selectedLocation} mb-2 d-flex`}>
                <h6>Email:</h6>
                <p>{selectedLocation ? selectedLocation.email : ""}</p>
              </div>
              <div className={`${classes.selectedLocation} mb-2 d-flex`}>
                <h6>Address:</h6>
                <p>{selectedLocation ? selectedLocation.address : ""}</p>
              </div>
            </div>
            <div className={classes.ordersummaryRight}>
              {summaryValue && (
                <form>
                  <div className={classes.orderAmountList}>
                    <div className="mb-2 d-flex justify-content-between">
                      <h6>No. of Products</h6>
                      <h6>
                        <b>{summaryValue.length}</b>
                      </h6>
                    </div>
                    {/* <div className="mb-2 d-flex justify-content-between">
                      <h6>Rate</h6>
                      <h6>
                        <b>₹{calculateTotalAmount(summaryValue).toFixed(2)}</b>
                      </h6>
                    </div> */}
                    {/* <div className=" mb-2 d-flex justify-content-between">
                      <h6>Discount</h6>
                      <h6>
                        <b>₹0.00</b>
                      </h6>
                    </div> */}
                  </div>
                  <div
                    className={`${classes.TotalAmount} mb-3 d-flex justify-content-between`}
                  >
                    <h6>Total Amount</h6>
                    <h6>
                      <b>₹{calculateTotalAmount(summaryValue).toFixed(2)}</b>
                    </h6>
                  </div>

                  <div
                    className={`${classes.RemarkBox} mb-2 d-flex flex-column`}
                  >
                    <label>Remark</label>
                    <textarea
                      className="p-2"
                      placeholder="Write your text here...."
                      onChange={(e) => setOrderRemark(e.target.value)}
                    ></textarea>
                  </div>

                  <div className={`${classes.selectedLocation} mb-4`}>
                    <h6 className="mb-2">Order Type:</h6>

                    {selectedLocation?.b2bDispatchOption === "Pick up" && (
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          id="Pick up"
                          name="orderType"
                          value="P"
                          checked={orderType === "P"}
                          onChange={handleOrderTypeChange}
                        />
                        <label className="mx-1" htmlFor="Pick up">
                          Pickup
                        </label>
                      </div>
                    )}

                    {selectedLocation?.b2bDispatchOption === "Delivery" && (
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          id="Delivery"
                          name="orderType"
                          value="D"
                          checked={orderType === "D"}
                          onChange={handleOrderTypeChange}
                        />
                        <label className="mx-1" htmlFor="Delivery">
                          Delivery
                        </label>
                      </div>
                    )}

                    {selectedLocation?.b2bDispatchOption === "Both" && (
                      <div className="d-flex gap-3 align-items-center">
                        <div className="d-flex align-items-center">
                          <input
                            type="radio"
                            id="Delivery"
                            name="orderType"
                            value="D"
                            checked={orderType === "D"}
                            onChange={handleOrderTypeChange}
                          />
                          <label className="px-1" htmlFor="Delivery">
                            Delivery
                          </label>
                        </div>
                        <div className="d-flex align-items-center">
                          <input
                            type="radio"
                            id="Pick up"
                            name="orderType"
                            value="P"
                            checked={orderType === "P"}
                            onChange={handleOrderTypeChange}
                          />
                          <label className="px-1" htmlFor="Pick up">
                            Pickup
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className={classes.Btn}
                      onClick={(e) => orderHandle(e)}
                    >
                      {loading === true ? <Loader /> : " Confirm Order"}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className={classes.successMsgModal}>
            The final bill amount would be mentioned in the invoice.
          </p>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateOrder;
