import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { format, startOfMonth } from "date-fns";
import classes from "./Order.module.css";
import styles from "../productDetail/ProductDetail.module.css";
import customs from "../Ledger/Ledger.module.css";
import NoOrder from "../../Assessts/Images/NoOrder.png";
import Header from "../../reusableComponents/Header";
import SideDrawer from "../../reusableComponents/SideDrawer/SideDrawer";
import Loader from "../../reusableComponents/Loader/Loader";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import { productActions } from "../../Store/Product/ProductSlice";
import {
  getAdminOrderDetails,
  OrderDetailsHandler,
  OrderHistoryHandler,
} from "../../Store/OrderList/OrderListActions";
import { path } from "../../Router/Routes.js";
import { getProductByStoreHandler } from "../../Store/Product/ProductActions";
import {
  getOrderStatusColor,
  getStoreLocationHanlder,
} from "../../Store/StoreDetails/StoreActions";
import CustomDatePicker from "../../reusableComponents/CustomDatePicker/CustomDatePicker.js";

const OrderHistory = () => {
  const { id, locationid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  // eslint-disable-next-line
  const [searchBy, setSearchBy] = useState("orderId");
  const [searchQuery, setSearchQuery] = useState("");
  // eslint-disable-next-line
  const [statusFilter, setStatusFilter] = useState("all");
  const [orders, setOrders] = useState([]);
  const [pageloading, setPageLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // eslint-disable-next-line
  const [color, setColor] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [filterStatus, setFilterStatus] = useState(null);
  // eslint-disable-next-line
  const [location, setLocation] = useState([]);
  const [fromDate, setFromDate] = useState(format(startOfMonth(new Date()), "dd/MM/yyyy"));
  const [toDate, setToDate] = useState(format(new Date(), "dd/MM/yyyy"));
  // const [orderLocationId, setOrderLocationId] = useState("");
  const [orderFlag, setOrderFlag] = useState(false);

  useEffect(() => {
    handleViewClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!B2BauthToken) {
      toast.error("Please login first!");
      navigate(path.login);
    }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));

    const params = {
      ClientRegID: id,
      LocationID: locationid,
      page: "",
      limit: "",
      active: "",
      search: "",
      MfgGroupName: "",
    };

    dispatch(getProductByStoreHandler(params)).then((result) => {
      if (result.status === "success") {
        dispatch(productActions.setProductListHandler(result.data));
      } 
    });

    dispatch(getStoreLocationHanlder(id)).then((result) => {
      if (result.status === "success") {
        setLocation(result.data);
      }
      
    });
  }, [dispatch, locationid, id, B2BauthToken, navigate]);

  const handleViewClick = () => {
    setPageLoading(true);
   
    dispatch(
      OrderHistoryHandler(
        B2BauthToken,
        id,
        locationid,
        fromDate,
        toDate,
        filterStatus
      )
    ).then((result) => {
      if (result.status === "success") {
        setPageLoading(false);
        setOrders(result.data);
        dispatch(getOrderStatusColor(id)).then((result) => {
          if (result.status === "success") {
            setColor(result.data.OrderSt);
            const uniqueStatuses = [
              ...new Set(
                result.data.OrderSt.map((product) => product?.OrderStatusname)
              ),
            ];
            setStatusDropdown(uniqueStatuses);
          }
          
        });
      } 
       
      else {
        setPageLoading(false);
      }
    });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    setFilterStatus(e.target.value);
  };

  const filteredData = orders.filter((order) => {
    const searchFilter =
      searchBy === "orderId"
        ? (order?.RefNo).toLowerCase().includes(searchQuery.toLowerCase())
        : searchBy === "orderNumber"
        ? order?.RefNo.toLowerCase().includes(searchQuery.toLowerCase())
        : order.Party.toLowerCase().includes(searchQuery.toLowerCase());

    const statusFilterMatch =
      statusFilter === "all" ? true : order.SwilmartStatus === statusFilter;

    return searchFilter && statusFilterMatch;
  });

  const getStatusColor = (status) => {
    const statusObj = color.find(
      (item) => item.OrderStatusname.toLowerCase() === status.toLowerCase()
    );
    return statusObj ? statusObj.color : "#000";
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
    if (isDrawerOpen) {
      setSelectedOrder(null);
    }
  };

  const handleRowClick = (order) => {
    if (order?.PKID) {
      dispatch(OrderDetailsHandler(id, order?.PKID, order?.FKSeriesID)).then(
        (result) => {
          if (result.status === "success") {
            setSelectedOrder(result.data);
            toggleDrawer();
          } 
         
           else {
            toast.error(
              "There was an error fetching order details. Please try again later!"
            );
          }
        }
      );
      setOrderFlag(false);
    } else {
      dispatch(getAdminOrderDetails(order?.RefNo)).then((result) => {
        if (result.status === "success") {
          setSelectedOrder(result.data);
          toggleDrawer();
        }
       
         else {
          toast.error(
            "There was an error fetching order details. Please try again later!"
          );
        }
      });
      setOrderFlag(true);
    }
  };
  const isFromDateGreaterThanToDate = (from, to) => {
    const fromDate = new Date(from.split("/").reverse().join("-"));
    const toDate = new Date(to.split("/").reverse().join("-"));
    return fromDate > toDate;
  };

  return (
    <>
      <Header
        pagename={"Order History"}
      />

      <div className={classes.OrderHistorySetup}>
        <div className={`${classes.OrderHistoryFilters}`}>
          <div className={`${classes.OrderHistoryFilterOptions}`}>
            <div className="d-flex gap-2 align-center flex-column">
              <div className={`${classes.OrderDates}`}>
                <label className={`${customs.dateText}  `} htmlFor="fromDate">
                  From Date:
                </label>

                <CustomDatePicker
                  selectedDate={fromDate}
                  onChange={(date) => {
                    if (isFromDateGreaterThanToDate(date, toDate)) {
                      toast.error("From date cannot be greater than to date");
                      return;
                    }
                    setFromDate(date);
                  }}
                />
              </div>

              <div className={`${classes.OrderDates}`}>
                <label className={`${customs.dateText}  `} htmlFor="toDate">
                  To Date:
                </label>

                <CustomDatePicker
                  selectedDate={toDate}
                  onChange={(date) => {
                    if (isFromDateGreaterThanToDate(fromDate, date)) {
                      toast.error("To date cannot be less than from date");
                      return;
                    }
                    setToDate(date);
                  }}
                />
              </div>
            </div>
            <div className={`${classes.OrderStatusGroup}`}>
              <div
                className={`${classes.OrderStatusDropdown} status-filter ms-2 `}
              >
                <select className="form-select" onChange={handleStatusFilter}>
                  <option value="all">All Status</option>
                  <option value="Processing">Processing</option>
                  {/* <option value="failed">Failed</option> */}
                  {statusDropdown.map((status, index) => (
                    <option value={status}>{status}</option>
                  ))}
                </select>
              </div>

              
            </div>
            <div
              className={`${classes.OrderHistorySearch} OrderHistorySEtSearch `}
            >
              <input
                type="text"
                value={searchQuery}
                className=" search"
                placeholder={`Search ${
                  searchBy === "orderId"
                    ? "Order ID"
                    : searchBy === "orderNumber"
                    ? "Ref Number"
                    : "Customer Name"
                }`}
                onChange={handleSearch}
              />
              <i className="bi bi-search"></i>
            </div>
          </div>

          <div className={`${classes.OrderHistoryButtons}`}>
            <button className={classes.UpdateBtn} onClick={handleViewClick}>
              View
            </button>

           
          </div>
        </div>

        {pageloading === true ? (
          <div className={`${styles.PageWrapper}`}>
            <Loader />
          </div>
        ) : (
          <>
            {filteredData.length ? (
              <div className={`${classes.OrderhistoryTable}`}>
                <table className={`${styles.TableComponent}`}>
                  <thead className={`${styles.TableComponentHeader}`}>
                    <tr>
                      <th scope="col" className={`${styles.TableNum}`}>
                        Order ID
                      </th>

                      <th
                        scope="col"
                        className={`${styles.TableNum}`}
                      >
                        Order Number
                      </th>
                      <th
                        scope="col"
                        className={`${styles.TableName}`}
                      >
                        Order Location
                      </th>
                      <th
                        scope="col"
                        className={`${styles.TableNum} text-center`}
                      >
                        Order Date
                      </th>
                      <th
                        scope="col"
                        className={`${styles.TableNum} text-center`}
                      >
                        Status
                      </th>
                    
                      <th scope="col" className={`${styles.TableNum} text-end pe-4`}>
                        Amount
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody className="list form-check-all">
                    {filteredData.map((order, index) => (
                      <tr key={index} onClick={() => handleRowClick(order)}>
                        <td className={`${styles.TableNum}`}>{order?.RefNo}</td>

                        <td className={`${styles.TableNum}`}>
                          {order?.Series + " " + order?.EntryNo}
                        </td>
                        <td className={`${styles.TableName}`}>
                          {order?.StockLocation}
                        </td>
                        <td className={`${styles.TableNum} text-center`}>
                          {" "}
                          {new Date(order.EntryDate)
                            .toLocaleDateString("en-GB")
                            .replace(/\//g, "-")}
                        </td>

                        <td className={`${styles.TableNum} text-center`}>
                          {" "}
                          <p
                            className={`${classes.status} text-center w-100`}
                            style={{
                              color: getStatusColor(order.SwilmartStatus),
                            }}
                          >
                            {" "}
                            {order?.SwilmartStatus}
                          </p>
                        </td>
                       
                        <td className={`${styles.TableNum} text-end pe-4`}>
                          {order.NetAmt.toFixed(2)}
                        </td>

                      
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={`${styles.NoImageContainer}`}>
                <img src={NoOrder} alt="no-products-found" />
              </div>
            )}
          </>
        )}

        <SideDrawer
          isOpen={isDrawerOpen}
          onClose={toggleDrawer}
          order={selectedOrder}
          orderFlag={orderFlag}
        />
      </div>
    </>
  );
};

export default OrderHistory;
