import { CustomerInstance, StoreInstance } from "../../Router/AxiosInstance";
import { storeActions } from "./StoreSlice";

export const getAllStoreListHanlder = () => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
      data:[]
    };

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/b2b/get-b2b-stores",
    };

    try{

      const response = await StoreInstance(config);
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
        }
        dispatch(storeActions.setStoreListDetailHandler(response.data.data));
      }
      catch(error) {
        if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              result.status = "success";
              result.msg = "Bad Request! Please check your input parameters.";
              result.data = [];
  
              break;
            case 401:
              result.status = "success";
              result.msg = "Unauthorized! Please log in again.";
              result.data = [];
  
              break;
            case 403:
              result.status = "success";
              result.msg =
                "Forbidden! You don't have permission to access this resource.";
              result.data = [];
  
              break;
            case 404:
              result.status = "success";
              result.msg = "Resource not found! Please check the URL.";
              result.data = [];
  
              break;
            case 500:
              result.status = "success";
              result.msg = "Server error! Please try again later.";
              result.data = [];
  
              break;
            default:
              result.status = "success";
              result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
              result.data = [];
          }
        } else {
          result.status = "error";
          result.msg = "Network error! Please check your connection.";
        }
      };
    return result;
  };
};

export const getStoreStatusHanlder = (B2BauthToken) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/b2bCustomer/fetch-store-status",
      headers: {
        Authorization: "Bearer " + B2BauthToken,
      }
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.result;
        
        }
;
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response &&
           error.response.status === 500
        ) {
          result.status = "error";
            result.msg = "Server error! Please try again later.";
        }
      });
    return result;
  };
};

export const getStoreByIdHandle = (b2bAuthToken, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2b/get-store-details/${id}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + b2bAuthToken,
      },
    };

    try{

      const response = await StoreInstance(config);
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
          dispatch(storeActions.setStoreHandler(response.data.data));
        }

      }
      catch(error){
        if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              result.status = "success";
              result.msg = "Bad Request! Please check your input parameters.";
              result.data = [];
  
              break;
            case 401:
              result.status = "success";
              result.msg = "Unauthorized! Please log in again.";
              result.data = [];
  
              break;
            case 403:
              result.status = "success";
              result.msg =
                "Forbidden! You don't have permission to access this resource.";
              result.data = [];
  
              break;
            case 404:
              result.status = "success";
              result.msg = "Resource not found! Please check the URL.";
              result.data = [];
  
              break;
            case 500:
              result.status = "success";
              result.msg = "Server error! Please try again later.";
              result.data = [];
  
              break;
            default:
              result.status = "success";
              result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
              result.data = [];
          }
        } else {
          result.status = "error";
          result.msg = "Network error! Please check your connection.";
        }
      };
    return result;
  };
};

export const getLocationByIdHandle = (id, locationid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2b/getlocationbyId/${id}/${locationid}`,
    };

    try{

      const response = await StoreInstance(config);
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
          dispatch(storeActions.setLocationHandler(response.data.data));
        }

      }
      catch(error)  {
        if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              result.status = "success";
              result.msg = "Bad Request! Please check your input parameters.";
              result.data = [];
  
              break;
            case 401:
              result.status = "success";
              result.msg = "Unauthorized! Please log in again.";
              result.data = [];
  
              break;
            case 403:
              result.status = "success";
              result.msg =
                "Forbidden! You don't have permission to access this resource.";
              result.data = [];
  
              break;
            case 404:
              result.status = "success";
              result.msg = "Resource not found! Please check the URL.";
              result.data = [];
  
              break;
            case 500:
              result.status = "success";
              result.msg = "Server error! Please try again later.";
              result.data = [];
  
              break;
            default:
              result.status = "success";
              result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
              result.data = [];
          }
        } else {
          result.status = "error";
          result.msg = "Network error! Please check your connection.";
        }
      };
    return result;
  };
}

export const getSeriesInfoLocationwise = (id, locationid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2b/fetch-seriesId/${id}/${locationid}`,
    };

    try{

      const response = await StoreInstance(config);
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
          dispatch(storeActions.setLocationHandler(response.data.data));
        }

      }
      catch(error)  {
        if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              result.status = "success";
              result.msg = "Bad Request! Please check your input parameters.";
              result.data = [];
  
              break;
            case 401:
              result.status = "success";
              result.msg = "Unauthorized! Please log in again.";
              result.data = [];
  
              break;
            case 403:
              result.status = "success";
              result.msg =
                "Forbidden! You don't have permission to access this resource.";
              result.data = [];
  
              break;
            case 404:
              result.status = "success";
              result.msg = "Resource not found! Please check the URL.";
              result.data = [];
  
              break;
            case 500:
              result.status = "success";
              result.msg = "Server error! Please try again later.";
              result.data = [];
  
              break;
            default:
              result.status = "success";
              result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
              result.data = [];
          }
        } else {
          result.status = "error";
          result.msg = "Network error! Please check your connection.";
        }
      };
    return result;
  };
}

export const getStoreLocationHanlder = (id) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2b/fetch-locations/${id}`,
    };

    try{

      const response = await StoreInstance(config);
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;

        }
        dispatch(storeActions.setStoreLocDetailHandler(response.data.data));
      }
      catch(error) {
        if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              result.status = "success";
              result.msg = "Bad Request! Please check your input parameters.";
              result.data = [];
  
              break;
            case 401:
              result.status = "success";
              result.msg = "Unauthorized! Please log in again.";
              result.data = [];
  
              break;
            case 403:
              result.status = "success";
              result.msg =
                "Forbidden! You don't have permission to access this resource.";
              result.data = [];
  
              break;
            case 404:
              result.status = "success";
              result.msg = "Resource not found! Please check the URL.";
              result.data = [];
  
              break;
            case 500:
              result.status = "success";
              result.msg = "Server error! Please try again later.";
              result.data = [];
  
              break;
            default:
              result.status = "success";
              result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
              result.data = [];
          }
        } else {
          result.status = "error";
          result.msg = "Network error! Please check your connection.";
        } 
      };
    return result;
  };
};

export const getOrderStatusColor = (id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };


    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/admin/get-order-status/${id}`,
    };

    try{

      const response = await StoreInstance(config);
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
          dispatch(storeActions.setStoreHandler(response.data.data));
        }

      }
      catch(error)  {
        if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              result.status = "success";
              result.msg = "Bad Request! Please check your input parameters.";
              result.data = [];
  
              break;
            case 401:
              result.status = "success";
              result.msg = "Unauthorized! Please log in again.";
              result.data = [];
  
              break;
            case 403:
              result.status = "success";
              result.msg =
                "Forbidden! You don't have permission to access this resource.";
              result.data = [];
  
              break;
            case 404:
              result.status = "success";
              result.msg = "Resource not found! Please check the URL.";
              result.data = [];
  
              break;
            case 500:
              result.status = "success";
              result.msg = "Server error! Please try again later.";
              result.data = [];
  
              break;
            default:
              result.status = "success";
              result.msg = `Unexpected error occurred (Status: ${statusCode}).`;
              result.data = [];
          }
        } else {
          result.status = "error";
          result.msg = "Network error! Please check your connection.";
        }
      };
    return result;
  };
};

